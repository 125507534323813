import {
  cpc_currentPrice,
  miniMumCartValue,
  priceMarkerDecimals,
  calculatorStyling,
  cpc_configData,
  spaceRegex,
  specialCharactersRegex,
} from "./global_variables";
import {
  buildDialogBlock,
  buildErrorBlock,
  generateStep,
  formatPricing,
  PreventCheckoutOnEnter,
} from "./common_utils";
import { ChangeEvent, UpdatePrice, PriceUpdation } from "./price_calculation";
import { openDefaultEditor } from "../node_modules/@pqina/pintura/pintura.js";

window.PriceUpdation = PriceUpdation;
window.CheckNumberInputValidity = CheckNumberInputValidity;
window.ChangeEvent = ChangeEvent;
window.UpdatePrice = UpdatePrice;

export function buildCalculator(inpData) {
  var div = "";
  inpData.Elements.forEach(function (element, index) {
    switch (element.ElementType) {
      case "TextInput":
      case "NumberInputNew":
        var txtInp = buildNumberInput(element);
        div += txtInp;
        break;
      case "SelectList":
        var txtDdl = buildDropDown(element);
        div += txtDdl;
        break;
      case "ImageSelector":
        var txtDdl = buildImageSelector(element);
        div += txtDdl;
        break;
      case "PhotoEditor":
        var photoEditor = buildPhotoPicker(element);
        div += photoEditor;
        break;
      case "TextEditor":
        var txtInp = buildTextDisplayBlock(element);
        div += txtInp;
        break;
      case "ImageUpload":
        var imgInp = buildImageElement(element);
        div += imgInp;
        break;
      case "LongTextInput":
        var textInp = buildTextInput(element);
        div += textInp;
        break;
      case "Checkbox":
        var textInp = buildCheckboxInput(element);
        div += textInp;
        break;
      case "Radio":
        var radioInp = buildRadioElement(element);
        div += radioInp;
        break;
      case "Calculation":
        var txtInp = buildCalculationElement(element);
        div += txtInp;
        break;
      case "Lookup":
        var txtInp = buildLookupInput(element);
        div += txtInp;
        break;
      case "PriceMarker":
        var txtInp = buildPriceMarker(element);
        div += txtInp;
        break;
    }
  });

  div += buildVariantElement();

  return div;
}

function buildNumberInput(element) {
  var minValue = element.MinValue != undefined ? element.MinValue : 0;
  var maxValue = element.Maxvalue != undefined ? element.Maxvalue : 100000;
  let elemMaxDecimal =
    element.Maxdecimals != undefined ? element.Maxdecimals : 0;
  //minValue = Math.min(minValue, elemMaxDecimal);
  //maxValue = Math.round(maxValue, elemMaxDecimal);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let isQuantity = element.isQuantity != undefined ? element.isQuantity : false;
  var step =
    element.Maxdecimals != undefined ? generateStep(element.Maxdecimals) : 1;
  var conditionalDisplay = getConditionalDisplay(element);
  var parsedName = element.Name.replace(/"/g, "&quot;");
  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";
  return (
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    `</label><input onkeyup='PriceUpdation(this)' onchange='PriceUpdation(
      this)' data-identifier='` +
    element.Identifier +
    "' class='calc-prop numInput-cpc' step='" +
    step +
    "' min='" +
    minValue +
    "' max='" +
    maxValue +
    "' onblur='CheckNumberInputValidity(this," +
    elemMaxDecimal +
    ");' data-rangeenabled='" +
    element.value_range_enabled +
    "' data-calcname='" +
    element.Name +
    "' name=\"properties[" +
    parsedName +
    "]\" data-cpc_isquantity='" +
    isQuantity +
    "' value='" +
    element.DefaultValue +
    "' type='number'/></div>"
  );
}

function buildLookupInput(element) {
  var input1_minvalue =
    element.input1_minvalue != undefined ? element.input1_minvalue : 0;
  var input1_maxvalue =
    element.input1_maxvalue != undefined ? element.input1_maxvalue : 100000;

  let elem1MaxDecimal =
    element.input1_maxdecimals.Maxdecimals != undefined
      ? element.input1_maxdecimals.Maxdecimals
      : 0;
  //input1_minvalue = Math.min(input1_minvalue, elem1MaxDecimal);
  input1_maxvalue = Math.round(input1_maxvalue, elem1MaxDecimal);

  var input1_step =
    element.input1_maxdecimals != undefined
      ? generateStep(element.input1_maxdecimals)
      : 1;
  var input1_isFormula =
    element.input1_isformula != undefined ? element.input1_isformula : false;

  var input2_minvalue =
    element.input2_minvalue != undefined ? element.input2_minvalue : 0;
  var input2_maxvalue =
    element.input1_maxvalue != undefined ? element.input2_maxvalue : 100000;

  let elem2MaxDecimal =
    element.input2_maxdecimals.Maxdecimals != undefined
      ? element.input2_maxdecimals.Maxdecimals
      : 0;
  //input2_minvalue = Math.min(input2_minvalue, elem2MaxDecimal);
  input2_maxvalue = Math.round(input2_maxvalue, elem2MaxDecimal);

  var input2_step =
    element.input2_maxdecimals != undefined
      ? generateStep(element.input2_maxdecimals)
      : 1;
  var input2_isFormula =
    element.input2_isformula != undefined ? element.input2_isformula : false;

  var conditionalDisplay = getConditionalDisplay(element);
  var elementCreated = "";
  var classInput1 = input1_isFormula ? "hide-element" : "";
  var classInput2 = input2_isFormula ? "hide-element" : "";
  let classInput1Element = input1_isFormula
    ? "lookup-element-with-formula"
    : "";
  let classInput2Element = input2_isFormula
    ? "lookup-element-with-formula"
    : "";

  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;

  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";

  elementCreated +=
    "<div class='element " +
    classInput1 +
    "' data-element='" +
    element.Identifier +
    "_1' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.input1_label +
    toolTipHtml +
    "</label><input onfocusout='ChangeEvent(null)' data-lookup='true' data-lookupelementindex='1' data-identifier='" +
    element.Identifier +
    "' class='calc-prop " +
    classInput1Element +
    "' step='" +
    input1_step +
    "' data-minvalue='" +
    input1_minvalue +
    "' min='" +
    input1_minvalue +
    "' max='" +
    input1_maxvalue +
    "' onblur='validity.valid || (value = \"\");' data-calcname='" +
    element.input1_label +
    "' data-isformula='" +
    input1_isFormula +
    "' data-formula='" +
    element.input1_formula +
    "' name=\"properties[" +
    element.input1_label +
    "]\" value='" +
    element.input1_minvalue +
    "' type='number'/></div>";
  elementCreated +=
    "<div class='element " +
    classInput2 +
    "' data-element='" +
    element.Identifier +
    "_2' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.input2_label +
    toolTipHtml +
    "</label><input onfocusout='ChangeEvent(null)' data-lookup='true' data-lookupelementindex='2' data-identifier='" +
    element.Identifier +
    "' class='calc-prop " +
    classInput2Element +
    "' step='" +
    input2_step +
    "' data-minvalue='" +
    input2_minvalue +
    "' min='" +
    input2_minvalue +
    "' max='" +
    input2_maxvalue +
    "' onblur='validity.valid || (value = \"\");' data-calcname='" +
    element.input2_label +
    "' data-isformula='" +
    input2_isFormula +
    "' data-formula='" +
    element.input2_formula +
    "' name=\"properties[" +
    element.input2_label +
    "]\" value='" +
    element.input2_minvalue +
    "' type='number'/></div>";

  return elementCreated;
}

function buildTextDisplayBlock(element) {
  var textValue = element.TextValue != undefined ? element.TextValue : "";
  var conditionalDisplay = getConditionalDisplay(element);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";

  return (
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    " ><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    textValue +
    toolTipHtml +
    "</label></div>"
  );
}

function buildImageElement(element) {
  var isrequired = element.isRequired;
  var conditionalDisplay = getConditionalDisplay(element);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";

  return (
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    "</label><input id='fileinput' class='calc-prop' data-identifier='" +
    element.Identifier +
    "' data-isrequired='" +
    isrequired +
    "' type='file' name=\"properties[" +
    element.Name +
    ']" /></div>'
  );
}

function buildPhotoPicker(element) {
  var isrequired = element.isRequired;
  var conditionalDisplay = getConditionalDisplay(element);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let editButtonProps = element.properties ?? {};
  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";

  let img_data = "";

  window.openEditor = function () {
    const editor = openDefaultEditor({
      src: img_data,
    });

    editor.on("close", () => {
      updateAndShowPreview();
    });

    editor.on("process", (res) => {
      updateAndShowPreview(res);
    });

    $("#previewloader").show();
    $("#file-preview").hide();
    $(".photoEditor_Editbutton").hide();
  };

  const updateAndShowPreview = (res) => {
    if (!!res) {
      img_data = res.dest;
      document
        .querySelector("#file-preview")
        ?.setAttribute("src", URL.createObjectURL(res.dest));

      let fileInput = document.getElementById("photoeditor");
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(res.dest);
      fileInput.files = dataTransfer.files;
    }

    $("#previewloader").hide();
    $("#file-preview").show();
    $(".photoEditor_Editbutton").show();
  };

  window.previewPhoto = (changeEvent) => {
    let input = document.getElementById("photoeditor");
    const file = input.files;
    if (file) {
      const fileReader = new FileReader();
      let preview = document.getElementById("file-preview");
      fileReader.onload = function (event) {
        //Set image as base64 string
        img_data = event.target.result;
        preview.setAttribute("src", img_data);

        //open editor on file upload
        if (!!changeEvent?.type) openEditor();
        //show preview on receving edited file
        else {
          $("#previewloader").hide();
          $("#file-preview").show();
          $(".photoEditor_Editbutton").show();
        }
      };
      fileReader.readAsDataURL(file[0]);
    }
  };

  return `<div class='element' data-element='${element.Identifier}'
    ${conditionalDisplay.conditionalTemplate}
    >
    <label style='color:${calculatorStyling.upperTextColor}'>
    ${element.Name} 
    ${toolTipHtml} 
    </label>
    <input 
    type="file"
    id='photoeditor' 
    class='calc-prop' 
    data-identifier='${element.Identifier}' 
    data-isrequired=${isrequired}
    name = 'properties[${element.Name}]'
    onchange='previewPhoto(event)'/>
    <div class="photo-editor-container">
      <div id="previewloader" style='display:none'>
        <img  src="https://app.custompricecalculator.com/loading-spinner.gif"/>
        <p>Loading Preview</p>
      </div>
      <img id="file-preview" />
      <div 
      class="photoEditor_Editbutton" 
      style ='display:none;background-color:${editButtonProps?.editButtonBG
    };color:${editButtonProps?.editButtonTextColor}' 
      onclick="openEditor()">
      ${!!editButtonProps.editButtonText
      ? editButtonProps.editButtonText
      : "Edit"
    } 
      </div>
    </div>
    </div>`;
}

function buildTextInput(element) {
  var minValue = element.MinCharacters != undefined ? element.MinCharacters : 0;
  var maxValue =
    element.MaxCharacters != undefined ? element.MaxCharacters : 100000;
  var isrequired = element.isRequired;
  var conditionalDisplay = getConditionalDisplay(element);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<span class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></span>"
      : "";

  return (
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    "</label><textarea class='calc-prop' data-identifier='" +
    element.Identifier +
    "' data-isrequired='" +
    isrequired +
    "' minlength='" +
    minValue +
    "' maxlength='" +
    maxValue +
    "' onblur='UpdatePrice() || validity.valid || (value = \"\");' data-calcname='" +
    element.Name +
    "' name=\"properties[" +
    element.Name +
    "]\" placeholder='" +
    element.Placeholder +
    "' data-lengthToggle='" +
    element.lengthToggle +
    "'></textarea></div>"
  );
}

function buildCheckboxInput(element) {
  var checkedValue = element.checkedValue;
  var uncheckedValue = element.uncheckedValue;
  var isrequired = element.isRequired;
  var conditionalDisplay = getConditionalDisplay(element);
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<div class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></div>"
      : "";

  return (
    "<div class='element checkbox-element' data-element='" +
    element.Identifier +
    "' data-isrequired='" +
    isrequired +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "'><input class='calc-prop checkbox' data-identifier='" +
    element.Identifier +
    "' data-isrequired='" +
    isrequired +
    "'' onchange='ChangeEvent(null)' type='checkbox' value='" +
    checkedValue +
    "' name=\"properties[" +
    element.Name +
    "]\" data-calcname='" +
    element.Name +
    "' data-checkedvalue='" +
    checkedValue +
    "' data-uncheckedvalue='" +
    uncheckedValue +
    "'/><span style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    "</span></div>"
  );
}

function buildRadioElement(element) {
  var conditionalDisplay = getConditionalDisplay(element);
  var parsedName = element.Name.replace(/"/g, "&quot;");
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<div class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></div>"
      : "";

  var res =
    "<div class='element checkbox-element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "'> " +
    element.Identifier +
    toolTipHtml +
    "<br/>";
  let radioIteration = 0;
  element.Options.forEach((option) => {
    let radioSelectedText = radioIteration === 0 ? "checked" : "";
    res +=
      "<input class='calc-prop radio' data-identifier='" +
      element.Identifier +
      "' type='radio' onchange='ChangeEvent(null)' data-val='" +
      option.value +
      "' value='" +
      option.label +
      "' name=\"properties[" +
      parsedName +
      ']" ' +
      radioSelectedText +
      " data-calcname='" +
      element.Name +
      "'/><span style='color:" +
      calculatorStyling.upperTextColor +
      "'>" +
      option.label +
      "</span>";
    radioIteration++;
  });

  res += "</div>";
  return res;
}

function buildVariantElement() {
  return "<input id='variantIdCustom' name='id' type='hidden' value='0' />";
}

function buildDropDown(element) {
  var conditionalDisplay = getConditionalDisplay(element);
  var parsedName = element.Name.replace(/"/g, "&quot;");
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<div class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></div>"
      : "";

  var ddl =
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    "</label><select style='border:1px solid yellow' data-identifier='" +
    element.Identifier +
    "' onchange='ChangeEvent(null, this)' class='calc-prop' data-calcname='" +
    parsedName +
    "' name=\"properties[" +
    parsedName +
    ']">';

  element.Options.forEach((option) => {
    let escapedLabel = option.label
      .replace(/'/g, "&#39;")
      .replace(/"/g, "&quot;");
    ddl +=
      "<option data-val='" +
      option.value +
      "' data-label='" +
      escapedLabel +
      "'>" +
      option.label +
      "</option>";
  });
  ddl += "</select ></div >";

  return ddl;
}

function buildImageSelector(element) {
  var conditionalDisplay = getConditionalDisplay(element);
  var enableSwatch = element.enableSwatch;
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<div class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></div>"
      : "";
  var ddl = "";
  if (enableSwatch === false) {
    ddl =
      "<div class='element' " +
      conditionalDisplay.conditionalTemplate +
      "><label style='color:" +
      calculatorStyling.upperTextColor +
      "'>" +
      element.Name +
      toolTipHtml +
      "</label><select style='border:1px solid yellow' data-showimage='" +
      element.showImage +
      "' data-identifier='" +
      element.Identifier +
      "' onchange='ChangeEvent(\"" +
      element.ElementType +
      "\", this)' class='calc-prop' data-calcname='" +
      element.Name +
      "' name=\"properties[" +
      element.Name +
      '">';

    element.Options.forEach((option) => {
      ddl +=
        "<option data-val='" +
        option.value +
        "' data-label='" +
        option.label +
        "' data-imgsrc='" +
        option.filepath +
        "'  >" +
        option.label +
        "</option>";
    });
    ddl += "</select ></div>";
    element.Options.forEach((option) => {
      ddl += "<img src='" + option.filepath + "' style='display:none' />";
    });
  } else {
    ddl =
      "<div class='element' " +
      conditionalDisplay.conditionalTemplate +
      "><label class='element-label' data-identifier='" +
      element.Identifier +
      "' style='color:" +
      calculatorStyling.upperTextColor +
      "'>" +
      element.Name +
      toolTipHtml +
      "</label><fieldset class='swatch-picker'>";

    let swatchIndex = 0;
    element.Options.forEach((option) => {
      swatchIndex++;
      let swatchedChecked = swatchIndex === 1 ? "checked" : "";
      ddl +=
        "<labele class='swatch-label ctooltip' title='" +
        option.label +
        "'><input type='radio' class='calc-prop' data-identifier='" +
        element.Identifier +
        "' " +
        swatchedChecked +
        " data-showimage='" +
        element.showImage +
        "' value='" +
        option.label +
        "' data-val='" +
        option.value +
        "' data-imgsrc='" +
        option.filepath +
        "' data-calcname='" +
        element.Name +
        "' name=\"properties[" +
        element.Name +
        ']" onchange=\'ChangeEvent("' +
        element.ElementType +
        "\", this)' /><img src='" +
        option.filepath +
        "' /></labele>";
    });
    ddl += "</fieldset></div>";
  }
  return ddl;
}
function getConditionalDisplay(element) {
  var showElement = element.conditional_display === true ? false : true;
  var displayStyle = showElement === false ? "style='display:none;'" : "";
  var conditionalElement = element.conditional_display_selected_element;
  var conditionalElementValue =
    element.conditional_display_selected_element_value;

  return {
    displayStyle: displayStyle,
    conditionalElement: conditionalElement,
    conditionalElementValue: conditionalElementValue,
    conditionalTemplate:
      displayStyle +
      " data-conditionalelement='" +
      conditionalElement +
      "' data-conditionalelementvalue='" +
      conditionalElementValue +
      "'",
  };
}

function buildCalculationElement(element) {
  let formulaResult = parseFloat(element.formula_minvalue);
  formulaResult = Number(formulaResult).toFixed(element.formula_decimals);
  var conditionalDisplay = getConditionalDisplay(element);
  let isQuantity = element.isQuantity != undefined ? element.isQuantity : false;
  var processedElementName = element.Name.replace(spaceRegex, "_");
  processedElementName = processedElementName.replace(
    specialCharactersRegex,
    ""
  );
  let enableToolTip =
    element.enableToolTip != undefined ? element.enableToolTip : false;
  let toolTip = enableToolTip ? element.toolTip : null;
  let toolTipHtml =
    enableToolTip === true
      ? "<div class='c-tooltip'><i class='fas fa-info-circle'></i><span class='right'>" +
      toolTip +
      "<i></i></span></div>"
      : "";
  return (
    "<div class='element' data-element='" +
    element.Identifier +
    "' " +
    conditionalDisplay.conditionalTemplate +
    "><label style='color:" +
    calculatorStyling.upperTextColor +
    "'>" +
    element.Name +
    toolTipHtml +
    "</label><span class='formula-block'> " +
    element.formula_prefix +
    " <label class='calculation-element calc-prop' name=\"properties[" +
    element.Name +
    "]\" data-minvalue='" +
    element.formula_minvalue +
    "' data-element_name='" +
    element.Name +
    "' data-val='" +
    element.formula_minvalue +
    "' data-decimals='" +
    element.formula_decimals +
    "' data-formula='" +
    element.formula +
    "' data-cpc_isquantity='" +
    isQuantity +
    "' id='formula_" +
    element.Name +
    "'>" +
    formulaResult +
    "</label><suffix>" +
    element.formula_suffix +
    "</suffix></span></div><input type='hidden' id='calculation_inp_" +
    processedElementName +
    "' name=\"properties[" +
    element.Name +
    "]\" data-cpc_isquantity='" +
    isQuantity +
    "' value='" +
    element.formula_minvalue +
    "' />"
  );
}

function buildPriceMarker(element) {
  cpc_currentPrice = element.DefaultValue;
  priceMarkerDecimals = element.Decimals;
  miniMumCartValue = parseFloat(element.DefaultValue);
  miniMumCartValue = Number(miniMumCartValue).toFixed(element.Decimals);
  var borderClass =
    calculatorStyling.border === "rounded" ? "rounded-edges-bottom" : "";
  var errorBlock = buildErrorBlock();
  var dialogBlock = buildDialogBlock();
  if (calculatorStyling.loaderText === null) calculatorStyling.loaderText = "";
  var loadingAnim =
    "<div class='loading-anim' style='display:none'><img src='https://app.custompricecalculator.com/loading-spinner.gif'><p id='cpc_loader_text'>" +
    calculatorStyling.loaderText +
    "</p></div>";
  var modal = "<div id='errDialog' title='Title' style=display:none;'>" +
    "<p id='dialogMsg'> Message. </p>" +
    "</div>";
  return (
    errorBlock +
    dialogBlock +
    loadingAnim +
    modal +
    "<div class='price-marker " +
    borderClass +
    "' style='background-color:" +
    calculatorStyling.priceBackground +
    ";color:" +
    calculatorStyling.priceTextColor +
    "'><label style='color:" +
    calculatorStyling.priceTextColor +
    "'>" +
    element.Name +
    "</label><span id='calculator-final-value'> " +
    element.Prefix +
    " <label id='priceTicker' style='color:" +
    calculatorStyling.priceTextColor +
    "'>" +
    formatPricing(
      Number(miniMumCartValue),
      +cpc_configData.thousandSeperator,
      +cpc_configData.decimalSeperator
    ) +
    "</label><suffix>" +
    element.Suffix +
    "</suffix></span></div>"
  );
}

function setAttributes(el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

export function CheckNumberInputValidity(element, elemMaxDecimal) {
  let alertMessage = cpc_configData.alertMessageNumberInput;
  let inValidCount = 0;
  let inValidProp = "";
  let isValid = true;
  var inputValue = element.value;
  var decimalPlaces = (inputValue.split(".")[1] || "").length;
  if (!element.validity.valid) {
    for (var key in element.validity) {
      if (element.validity[key]) {
        inValidCount++;
        inValidProp = key.toLowerCase();
      }
    }
  }
  //if(inValidCount>1 || (inValidCount == 1 && inValidProp!='stepmismatch'))
  //if(inValidCount>1 || (inValidCount == 1))
  if (
    inValidCount > 1 ||
    (element.step == 1 && inValidProp == "stepmismatch") ||
    decimalPlaces > elemMaxDecimal ||
    (inValidCount == 1 &&
      inValidCount == 1 &&
      inValidProp != "stepmismatch" &&
      ($(element).data("lookup") == null ||
        ($(element).data("lookup") == undefined &&
          $(element).data("lookup") != true)))
  )
    isValid = false;

  if (element.value.length == 0 || !isValid) {
    element.setAttribute("style", "border:1px solid red !important;");
    element.value = element.min || 0;
    if (element.nextSibling == null) {
      if (
        alertMessage
          .replace("{input_range_min}", "")
          .replace("{input_range_max}", "")
          .trim().length > 0
      ) {
        let min = element.getAttribute("min");
        let max = element.getAttribute("max");
        alertMessage = alertMessage
          .replace("{input_range_min}", min)
          .replace("{input_range_max}", max)
          .trim();
        element.insertAdjacentHTML(
          "afterend",
          "<label style='color:red;'>" + alertMessage + "</label>"
        );
      } else
        element.insertAdjacentHTML(
          "afterend",
          "<label style='color:red;'>Value out of range</label>"
        );
    }
    UpdatePrice();
    return false;
  }

  element.style.setProperty("border", "");

  if (element.nextSibling != null) element.nextSibling.remove();

  UpdatePrice();
  return true;
}

$(window).ready(function () {
  PreventCheckoutOnEnter();
});
