import {
  cpc_shopUrl,
  baseCalculatorNewApiUrl,
  cpc_configData,
  cpc_fasterCheckoutConfig
} from "./global_variables";
import { formatPricing } from "./common_utils";

export async function ProcessCartPage() {
  window.CPC_CartQuantityOnChange = CARTPAGE_adjustLineItems;
  await LoadStoreConfig();
  CARTPAGE_adjustLineItems();
}

var cartDataFromShopify = []

function CARTPAGE_adjustLineItems() {
  jQuery('input[class*="qty"]').on("change", function () {
    setTimeout(() => {
      console.log("quantity changed");
      CARTPAGE_adjustLineItems();
    }, 1000);
  });

  //Remove the cart unique_id
  var all = document.querySelectorAll("li,p,span,label");
  var regexPattern = /[a-zA-Z0-9: ]?cpc_[a-zA-Z0-9]+/gi;
  for (var i = 0, max = all.length; i < max; i++) {
    //let elem = all[i];
    //if (regexPattern.test(elem.textContent)) {
    //    $(elem).html('');
    //}
  }

  fetch("/cart.js?cache=false&q=" + Math.random())
    .then((response) => response.json())
    .then(async function (cartData) {
      cartDataFromShopify = cartData;
      let cart_items = cartData.items;
      let cart_total_price = cartData.total_price;
      let cart_original_total_price = cartData.original_total_price;
      let allVariantsFromCart = [];
      for (let i = 0; i < cart_items.length; i++) {
        allVariantsFromCart.push({index: i, item: cart_items[i]});
      }

      var missingVariants = allVariantsFromCart.map(a=>a.item).filter(item => item.price === 0);
      missingVariants = missingVariants.length > 0 ? missingVariants: allVariantsFromCart.length>0 ? [allVariantsFromCart[0].item] : [];
      
      var cpc_variantData = await CARTPAGE_LoadVariants(missingVariants);
      if(cpc_fasterCheckoutConfig.fasterCheckoutConfig !== null && cpc_fasterCheckoutConfig.fasterCheckoutConfig.enabled === true) 
      {
        console.log('Faster checkout behavior: New')
        //Use new faster checkout behavior
        UpdateCartElement(allVariantsFromCart, cpc_variantData);
      } 
      else {
        if (!!cpc_variantData && cpc_variantData.length > 0) {
          for (let i = 0; i < cpc_variantData.length; i++) {
            let currentVariant = cpc_variantData[i];
            let cpc_quantity = 0;
            jQuery.each(allVariantsFromCart, function (i, cartVariant) {
              if (cartVariant.item.variant_id == currentVariant.shopifyVariantId) {
                  //This variant price info is missing from cart
                  cpc_quantity = cartVariant.item.quantity;
                  if(cartVariant.item.price === 0)
                  {
                    cart_total_price += currentVariant.amount * cpc_quantity * 100;
                  }
            }
          });

            jQuery(
              "cpc.product_title[data-id='" +
                currentVariant.shopifyVariantId +
                "']"
            ).html(currentVariant.shopifyProductTitle);
            jQuery(
              "cpc.original_price[data-id='" +
                currentVariant.shopifyVariantId +
                "']"
            ).html(
              CARTPAGE_formatPrice(cartData.currency, currentVariant.amount)
            );
            jQuery(
              "cpc.price[data-id='" + currentVariant.shopifyVariantId + "']"
            ).html(
              CARTPAGE_formatPrice(cartData.currency, currentVariant.amount)
            );
            jQuery(
              "cpc.discounted_price[data-id='" +
                currentVariant.shopifyVariantId +
                "']"
            ).html(
              CARTPAGE_formatPrice(cartData.currency, currentVariant.amount)
            );

            jQuery(
              "cpc.final_line_price[data-id='" +
                currentVariant.shopifyVariantId +
                "']"
            ).html(
              CARTPAGE_formatPrice(
                cartData.currency,
                currentVariant.amount * cpc_quantity
              )
            );
            jQuery(
              "cpc.line_price[data-id='" + currentVariant.shopifyVariantId + "']"
            ).html(
              CARTPAGE_formatPrice(
                cartData.currency,
                currentVariant.amount * cpc_quantity
              )
            );
            jQuery(
              "cpc.original_line_price[data-id='" +
                currentVariant.shopifyVariantId +
                "']"
            ).html(
              CARTPAGE_formatPrice(
                cartData.currency,
                currentVariant.amount * cpc_quantity
              )
            );

            var itemImage = jQuery(
              "img[data-cpcimageid='" + currentVariant.shopifyVariantId + "']"
            );
            if (
              itemImage !== undefined &&
              currentVariant.productImageUrl !== null &&
              itemImage.length > 0
            ) {
              itemImage[0].setAttribute("src", currentVariant.productImageUrl);
              itemImage[0].setAttribute(
                "data-src",
                currentVariant.productImageUrl
              );
            }
          }

          jQuery("cpc.final_price").html(
            CARTPAGE_formatPrice(cartData.currency, cart_total_price / 100, true)
          );
          jQuery("cpc.cart_total_price").html(
            CARTPAGE_formatPrice(cartData.currency, cart_total_price / 100, true)
          );
        }
      }
    });
}

function CARTPAGE_formatPrice(currencyCode, amount, isTotalField) {
  let amountFormated = Number(amount).toFixed(2);
  let cpc_CurrencySymbol = CARTPAGE_getCurrency(currencyCode, isTotalField);

  return (
    cpc_CurrencySymbol.before +
    formatPricing(
      Number(amountFormated),
      +cpc_configData.thousandSeperator,
      +cpc_configData.decimalSeperator,
      true
    ) +
    " " +
    cpc_CurrencySymbol.after
  );
}

function CARTPAGE_getCurrency(currencyCode, isTotalField = false) {
  var currencyCodeUpperCase = currencyCode.toUpperCase();
  let shouldIncludeCurrencySuffix = isTotalField && cpc_fasterCheckoutConfig.checkoutConfig?.includeCurrencySuffix === true;
  
  switch (currencyCodeUpperCase) {
    case "USD":
      return { before: "$", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "" };
      break;
    case "CAD":
      return {
        before: "$",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "CAD",
      };
      break;
    case "DKK":
      return { before: "", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "KR" };
      break;
    case "GBP":
      return {
        before: "&pound;",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "",
      };
      break;
    case "AUD":
      return {
        before: "$",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "AUD",
      };
      break;
    case "BRL":
      return { before: "R$", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "" };
      break;
    case "ZAR":
      return { before: "R", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "" };
      break;
    case "SGD":
      return { before: "$", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "" };
      break;
    case "SGD":
      return { before: "$", after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "" };
      break;
    case "EUR":
      return {
        before: "&euro;",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "",
      };
      break;
    case "MAD":
      return { before: "", after: "dh" };
      break;
    case "OMR":
      return {
        before: "",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : currencyCodeUpperCase,
      };
      break;
    case "TRY":
      return {
        before: "",
        after: shouldIncludeCurrencySuffix ? currencyCodeUpperCase : "&#8378;",
      };
      break;
    case "CLP":
      return { before: "$", after: "" };
      break;
    case "IDR":
      return { before: "RP", after: "" };
      break;
    case "TRY":
      return { before: "", after: "TL" };
      break;
    case "MXN":
      return { before: "$", after: "" };
      break;
    case "MYR":
      return { before: "RM ", after: "" };
      break;
    default:
      return { before: "", after: currencyCode.toUpperCase() };
      break;
  }
}

async function CARTPAGE_LoadVariants(variants) {
  //Create Variant
  let cpc_variantData = null;
  let n = new Request(baseCalculatorNewApiUrl + "variant:fetchvariants", {
    method: "POST",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify({ shop: cpc_shopUrl, variants: variants }),
  });
  await fetch(n)
    .then((response) => response.json())
    .then(async (variants) => {
      cpc_variantData = variants;
    });

  return cpc_variantData;
}

async function UpdateCartElement(allVariantsFromCart, cpc_variantData)
{
    if(cpc_fasterCheckoutConfig === null) {
      console.log('faster checkout configuration not found for the store')
      return;
    }

    console.log('cartData', cartDataFromShopify);
    console.log('allVariantsFromCart', allVariantsFromCart);
    var cartPriceElements = document.querySelectorAll(cpc_fasterCheckoutConfig.fasterCheckoutConfig.itemPriceSelector)
    var totalPriceElement = document.querySelectorAll(cpc_fasterCheckoutConfig.fasterCheckoutConfig.totalPriceSelector)
    var cartItemTitleElements = document.querySelectorAll(cpc_fasterCheckoutConfig.fasterCheckoutConfig.titleSelector)
    var productImageElements = cpc_fasterCheckoutConfig.fasterCheckoutConfig.productImageSelector === null ? [] 
                              : document.querySelectorAll(cpc_fasterCheckoutConfig.fasterCheckoutConfig.productImageSelector)

    console.log('totalPriceElement', totalPriceElement);
    let cart_total_price = (cartDataFromShopify.total_price) / 100;

    if(cartPriceElements.length === cartDataFromShopify.items.length)
    {
      //Update Cart Price
      cartPriceElements.forEach((priceElement, index) => {
          var elem = allVariantsFromCart.find(function(obj) { return obj.index === index; });
          if(elem === undefined) {
            console.log(`Skipping index ${index + 1}`)
            return;
          }

          var variantId = elem.item.variant_id;
          var cpcVariantInfo = cpc_variantData.find(a=>a.shopifyVariantId == variantId)
          if(cpcVariantInfo !== undefined) {

            //Update line item cost
            var totalLineCost = cpcVariantInfo.amount * elem.item.quantity
            cart_total_price += totalLineCost
            //console.log('totalLineCost', totalLineCost)
            if (hasChildElements(priceElement)) {
                replaceCost(priceElement.children[0], totalLineCost);
                //console.log(`Element ${index + 1} contains child elements.`, priceElement.children[0]);
            } else {
                replaceCost(priceElement, totalLineCost);
                //console.log(`Element ${index + 1} does not contain child elements.`, priceElement);
            }

            //Update Product Title
            var titleElement = cartItemTitleElements.length > index ? cartItemTitleElements[index] : null;
            if(titleElement !== null && titleElement !== undefined)
            {
              if (hasChildElements(titleElement)) {
                  replaceTextIfEmpty(titleElement.children[0], cpcVariantInfo.shopifyProductTitle);
                  //console.log(`Element ${index + 1} contains child elements.`, titleElement.children[0]);
              } else {
                  replaceTextIfEmpty(titleElement, cpcVariantInfo.shopifyProductTitle);
                  //console.log(`Element ${index + 1} does not contain child elements.`, titleElement);
              }
            }

            //Update Product Image
            var productImage = productImageElements.length > index ? productImageElements[index]: null;
            if(productImage !== null && productImage !== undefined)
            {
              var imageElementHtml = $(productImage).html();
              if(imageElementHtml.includes("<img"))
                productImage.setAttribute('src', cpcVariantInfo.productImageUrl);
              else
                {
                  //create image tag
                  var imgElement = document.createElement('img');
                  imgElement.src = cpcVariantInfo.productImageUrl;
                  imgElement.width = 150;
                  productImage.appendChild(imgElement);
                }
            }

            //Update total price
            if(totalPriceElement.length >=1)
            {
              priceElement = totalPriceElement[0];
              if (hasChildElements(priceElement)) {
                  replaceCost(priceElement.children[0], cart_total_price);
              } else {
                  replaceCost(priceElement, cart_total_price);
              }
            }
        }
      });
    } else {
      console.log(`Skipping faster checkout as the selector doesn't match. Selector count: ${cartPriceElements.length}, Cart Item Count = ${cartDataFromShopify.items.length}`)
    }
}

function hasChildElements(element) {
    return element.children.length > 0;
}

function replaceCost(container, cost) {
    var formattedPrice = CARTPAGE_formatPrice(cartDataFromShopify.currency, cost)
    container.textContent = formattedPrice;
}

function replaceTextIfEmpty(container, newText) {
    if(container.textContent.trim() === '')
        container.textContent = newText;
}


async function LoadStoreConfig() {
  var url =
    baseCalculatorNewApiUrl +
    "store/app?shop=" +
    cpc_shopUrl;

    await fetch(url).then((response) => response.json())
      .then((json) => {
        cpc_fasterCheckoutConfig = json;
  })
}